import validate from "/home/forge/merchants.strauss-qa.smake.eu/releases/20240917151933/node_modules/nuxt/dist/pages/runtime/validate.js";
import _01_45slug_45global from "/home/forge/merchants.strauss-qa.smake.eu/releases/20240917151933/middleware/01.slug.global.ts";
import _02_45refresh_45token_45global from "/home/forge/merchants.strauss-qa.smake.eu/releases/20240917151933/middleware/02.refresh-token.global.ts";
import _03_45authentication_45global from "/home/forge/merchants.strauss-qa.smake.eu/releases/20240917151933/middleware/03.authentication.global.ts";
import _04_45authorization_45global from "/home/forge/merchants.strauss-qa.smake.eu/releases/20240917151933/middleware/04.authorization.global.ts";
import manifest_45route_45rule from "/home/forge/merchants.strauss-qa.smake.eu/releases/20240917151933/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  _01_45slug_45global,
  _02_45refresh_45token_45global,
  _03_45authentication_45global,
  _04_45authorization_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {}