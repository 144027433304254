import { default as _91id_93ovcLCG8JRQMeta } from "/home/forge/merchants.strauss-qa.smake.eu/releases/20240917151933/pages/[slug]/catalog/logos/[id].vue?macro=true";
import { default as indexzKtmrP032CMeta } from "/home/forge/merchants.strauss-qa.smake.eu/releases/20240917151933/pages/[slug]/catalog/logos/index.vue?macro=true";
import { default as _91id_93hpOkUXG01hMeta } from "/home/forge/merchants.strauss-qa.smake.eu/releases/20240917151933/pages/[slug]/customers/[customer]/logos/[id].vue?macro=true";
import { default as indexvjT72xJxA3Meta } from "/home/forge/merchants.strauss-qa.smake.eu/releases/20240917151933/pages/[slug]/customers/[customer]/logos/index.vue?macro=true";
import { default as colorsbl2562v7pkMeta } from "/home/forge/merchants.strauss-qa.smake.eu/releases/20240917151933/pages/[slug]/customers/[id]/colors.vue?macro=true";
import { default as favorite_45variantsYViWCc9m8gMeta } from "/home/forge/merchants.strauss-qa.smake.eu/releases/20240917151933/pages/[slug]/customers/[id]/favorite-variants.vue?macro=true";
import { default as indexN0hW7B7Iy4Meta } from "/home/forge/merchants.strauss-qa.smake.eu/releases/20240917151933/pages/[slug]/customers/[id]/index.vue?macro=true";
import { default as indexva8pvU0CHzMeta } from "/home/forge/merchants.strauss-qa.smake.eu/releases/20240917151933/pages/[slug]/customers/index.vue?macro=true";
import { default as dashboardau3dD3oA6yMeta } from "/home/forge/merchants.strauss-qa.smake.eu/releases/20240917151933/pages/[slug]/dashboard.vue?macro=true";
import { default as entrancebkHdgM5Kf5Meta } from "/home/forge/merchants.strauss-qa.smake.eu/releases/20240917151933/pages/[slug]/entrance.vue?macro=true";
import { default as index54YrkTGbCOMeta } from "/home/forge/merchants.strauss-qa.smake.eu/releases/20240917151933/pages/[slug]/index.vue?macro=true";
import { default as loginLk47nCebCWMeta } from "/home/forge/merchants.strauss-qa.smake.eu/releases/20240917151933/pages/[slug]/login.vue?macro=true";
import { default as indexkimOaXCyyXMeta } from "/home/forge/merchants.strauss-qa.smake.eu/releases/20240917151933/pages/index.vue?macro=true";
export default [
  {
    name: "slug-catalog-logos-id",
    path: "/:slug()/catalog/logos/:id()",
    meta: _91id_93ovcLCG8JRQMeta || {},
    component: () => import("/home/forge/merchants.strauss-qa.smake.eu/releases/20240917151933/pages/[slug]/catalog/logos/[id].vue").then(m => m.default || m)
  },
  {
    name: indexzKtmrP032CMeta?.name ?? "slug-catalog-logos",
    path: "/:slug()/catalog/logos",
    meta: indexzKtmrP032CMeta || {},
    component: () => import("/home/forge/merchants.strauss-qa.smake.eu/releases/20240917151933/pages/[slug]/catalog/logos/index.vue").then(m => m.default || m)
  },
  {
    name: "slug-customers-customer-logos-id",
    path: "/:slug()/customers/:customer()/logos/:id()",
    meta: _91id_93hpOkUXG01hMeta || {},
    component: () => import("/home/forge/merchants.strauss-qa.smake.eu/releases/20240917151933/pages/[slug]/customers/[customer]/logos/[id].vue").then(m => m.default || m)
  },
  {
    name: "slug-customers-customer-logos",
    path: "/:slug()/customers/:customer()/logos",
    meta: indexvjT72xJxA3Meta || {},
    component: () => import("/home/forge/merchants.strauss-qa.smake.eu/releases/20240917151933/pages/[slug]/customers/[customer]/logos/index.vue").then(m => m.default || m)
  },
  {
    name: "slug-customers-id-colors",
    path: "/:slug()/customers/:id()/colors",
    meta: colorsbl2562v7pkMeta || {},
    component: () => import("/home/forge/merchants.strauss-qa.smake.eu/releases/20240917151933/pages/[slug]/customers/[id]/colors.vue").then(m => m.default || m)
  },
  {
    name: "slug-customers-id-favorite-variants",
    path: "/:slug()/customers/:id()/favorite-variants",
    meta: favorite_45variantsYViWCc9m8gMeta || {},
    component: () => import("/home/forge/merchants.strauss-qa.smake.eu/releases/20240917151933/pages/[slug]/customers/[id]/favorite-variants.vue").then(m => m.default || m)
  },
  {
    name: "slug-customers-id",
    path: "/:slug()/customers/:id()",
    meta: indexN0hW7B7Iy4Meta || {},
    component: () => import("/home/forge/merchants.strauss-qa.smake.eu/releases/20240917151933/pages/[slug]/customers/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexva8pvU0CHzMeta?.name ?? "slug-customers",
    path: "/:slug()/customers",
    meta: indexva8pvU0CHzMeta || {},
    component: () => import("/home/forge/merchants.strauss-qa.smake.eu/releases/20240917151933/pages/[slug]/customers/index.vue").then(m => m.default || m)
  },
  {
    name: dashboardau3dD3oA6yMeta?.name ?? "slug-dashboard",
    path: "/:slug()/dashboard",
    meta: dashboardau3dD3oA6yMeta || {},
    component: () => import("/home/forge/merchants.strauss-qa.smake.eu/releases/20240917151933/pages/[slug]/dashboard.vue").then(m => m.default || m)
  },
  {
    name: "slug-entrance",
    path: "/:slug()/entrance",
    meta: entrancebkHdgM5Kf5Meta || {},
    component: () => import("/home/forge/merchants.strauss-qa.smake.eu/releases/20240917151933/pages/[slug]/entrance.vue").then(m => m.default || m)
  },
  {
    name: "slug",
    path: "/:slug()",
    component: () => import("/home/forge/merchants.strauss-qa.smake.eu/releases/20240917151933/pages/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: loginLk47nCebCWMeta?.name ?? "slug-login",
    path: "/:slug()/login",
    meta: loginLk47nCebCWMeta || {},
    component: () => import("/home/forge/merchants.strauss-qa.smake.eu/releases/20240917151933/pages/[slug]/login.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/home/forge/merchants.strauss-qa.smake.eu/releases/20240917151933/pages/index.vue").then(m => m.default || m)
  }
]